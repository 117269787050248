import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { confimationStyles } from "../../../../assets/styles/toast";
import { getData } from "../../../../services";
import { loaderData, setLoading } from "../../../../store/slices/loaderSlice";
import ShareIcon from "../../../Icons/ShareIcon";
import BackButton from "../../../atoms/BackButton";
import BannerImage from "../../../atoms/BannerImage";
import IconCircle from "../../../atoms/IconCircle";
import Modal from "../../../molecules/Modal/Normal";
import ShareLink from "../../../organisms/blogShare/ShareLink";
import ShareLinkEmail from "../../../organisms/blogShare/ShareLinkEmail";
import "../../../organisms/blogShare/sharedBlog.scss";
import TxtFileRenderer from "./TxtFileRenderer";
import "./library.scss";
import ShimmerBlog from "./shimmer/shimmerBlog";

const View = () => {
  const { id, category } = useParams();
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();
  const loadingDataValue = useSelector(loaderData);

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const [generateURL, setGenerateURL] = useState();
  const [isCopy, setIsCopy] = useState(false);
  const [expiry, setExpiry] = useState(false);
  const [resourceData, setResourceData] = useState([]);

  const [blogData, setBlogData] = useState({
    header: "",
    created_date: "",
    text: "",
    company_name: "",
    cover_photo: "",
  }); // Corrected the initial state
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalId: "",
    title: "",
    isViewModalOpen: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getBlogData = async () => {
    setIsLoading(true);
    dispatch(setLoading(true));
    try {
      const res = await getData({
        endpoint: "Blog/getLibraryDetails",
        params: { blog_id: id },
        token: cookies.t,
      });
      dispatch(setLoading(false));
      setBlogData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Sharing
  const handleShare = (data) => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      FormData: data,
      // isViewModalOpen: true,
      isModalOpen: true,
    }));
  };

  const handleShareLinkEmail = () => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      isViewModalOpen: true,
    }));
  };

  const handleCopyUrl = () => {
    var text = document?.getElementById("primealphaurl")?.innerHTML;

    // Decode HTML entities
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    text = tempElement.textContent;

    // console.log("text", text);
    navigator?.clipboard?.writeText(text);
    setIsCopy(true);
    toast.success("Link Copied to clipboard.", {
      style: confimationStyles,
      duration: 1000,
    });
  };

  const getResourceData = useCallback(async (category) => {
    try {
      const res = await getData({
        endpoint: "Blog/customer/getAllLibraryBlogs",
        params: {
          categories: category ? JSON.stringify([atob(category)]) : null,
          page: 1,
          page_limit: 6,
        },
        token: cookies.t,
      });

      if (res) {
        return res;
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getBlogData();

        // Fetch resource data
        const libData = await getResourceData([category]);

        if (libData?.data?.length > 1) {
          setResourceData(libData);
        } else {
          const newLibData = await getResourceData(); // Await the async function call
          setResourceData(newLibData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id, category]);

  return (
    <div id="View-Library-Container">
      {loadingDataValue ? (
        <ShimmerBlog />
      ) : (
        <div className="resource-page-container">
          <div className="resource-container resource-view-container">
            <div className="d-flex justify-content-between">
              <BackButton tag="Back to Library" module="library" />

              {(userInfo?.has_master_access || userInfo?.content_sharing) && (
                <IconCircle
                  icon={<ShareIcon />}
                  border="1px solid #C0C4C9"
                  size={44}
                  handleClick={() => handleShare(blogData)}
                  color="#fffff"
                  isClickable={true}
                />
              )}
            </div>

            <>
              <div className="library-header-container">
                <div>
                  <h1 className="h1-i mt-4">{blogData?.header}</h1>
                  <div className="d-flex align-items-center mb-2">
                    <p className="p16 mb-0 p-dark-light light">
                      By {blogData?.company_name || "PrimeAlpha"}
                    </p>

                    <div className="dot-dark"></div>
                    <p className="p16 mb-0 p-dark-light light">
                      {moment
                        .utc(blogData?.date || blogData?.modified_date)
                        .format("MMM D, YYYY")}
                    </p>
                  </div>
                  <div className="mb-small">
                    <p className="p16 mb-0 p-dark medium">
                      {blogData?.category_name}
                    </p>
                  </div>
                </div>

                {/* {userInfo?.content_sharing && (
                    <IconCircle
                      icon={<ShareIcon />}
                      border="1px solid #C0C4C9"
                      size={44}
                      handleClick={handleShare}
                      color="#fffff"
                      isClickable={true}
                    />
                  )} */}
              </div>

              {blogData?.cover_photo && (
                <BannerImage
                  imageUrl={blogData?.cover_photo}
                  altText="cover"
                  type="cover"
                  style={{ marginBottom: "15px" }}
                />
              )}

              {/* <div className="content mt-large rich-text-editor-container "> */}
              <div
                className={`quill quill-change ${
                  blogData?.cover_photo ? "mt-3" : ""
                }`}
              >
                {/* <div
                  className="ql-container ql-snow ql-no-border"
                  style={{ background: "white" }}
                > */}
                {/* <div
                    className="ql-editor blog-content shared_blog mb-3"
                    dangerouslySetInnerHTML={{ __html: fileContent }}
                  ></div> */}
                {blogData?.text && <TxtFileRenderer fileUrl={blogData?.text} />}
                {/* </div> */}
              </div>
              {/* </div> */}

              {modalState.isModalOpen && (
                <Modal
                  id={modalState.modalId}
                  isActive={modalState.isModalOpen}
                  title={modalState.title}
                  width="642px"
                  onClose={() => {
                    setModalState((prev) => ({
                      ...prev,
                      isModalOpen: false,
                    }));
                    setGenerateURL(null);
                  }}
                  bodyStyle={{ padding: "24px" }}
                >
                  <ShareLink
                    id={modalState.modalId}
                    roomData={modalState.FormData}
                    name={modalState.FormData?.header}
                    handleShareLinkEmail={handleShareLinkEmail}
                    generateURL={generateURL}
                    setGenerateURL={setGenerateURL}
                    setExpiry={setExpiry}
                    isCopy={isCopy}
                    setIsCopy={setIsCopy}
                    handleCopyUrl={handleCopyUrl}
                    module="Library"
                    company_id={blogData?.company}
                  />
                </Modal>
              )}

              {modalState.isViewModalOpen && (
                <Modal
                  id={modalState.modalId}
                  isActive={modalState.isViewModalOpen}
                  title={modalState.title}
                  width="642px"
                  onClose={() =>
                    setModalState((prev) => ({
                      ...prev,
                      isViewModalOpen: false,
                    }))
                  }
                >
                  <ShareLinkEmail
                    generateURL={generateURL}
                    isCopy={isCopy}
                    handleCopyUrl={handleCopyUrl}
                    setModalStateEmail={setModalState}
                    setGenerateURL={setGenerateURL}
                    onClose={() =>
                      setModalState((prev) => ({
                        ...prev,
                        isViewModalOpen: false,
                      }))
                    }
                    expiry={expiry}
                    module="Library"
                  />
                </Modal>
              )}
            </>
          </div>

          <>
            <div className="extra-blogs">
              <h4 className="h2-j mb-3">Suggested Articles</h4>
              <hr />
              {resourceData?.data
                ?.filter((blog) => blog.id !== id) // Filter out the blog item with the same ID as the current page ID
                .slice(0, 5) // Return only the first 5 items
                .map((blog) => (
                  <div
                    key={blog.id}
                    className="extra-blog"
                    onClick={() =>
                      window.open(
                        `/dashboard/library/view/${blog.id}/${btoa(
                          blog?.category
                        )}`,
                        "_blank"
                      )
                    }
                  >
                    <div className="view-more mb-1">{blog?.category}</div>
                    <h4 className="h3-i mb-1">{blog.header}</h4>
                    <p className="p-dark-light mb-0">
                      By {blog?.company_name || "PrimeAlpha"}
                    </p>
                    <p className="p">
                      {moment
                        .utc(blog?.date || blog?.modified_date)
                        .format("MMM D, YYYY")}
                    </p>
                    <hr />
                  </div>
                ))}
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default View;
